export const FirebaseConfig = {
	"projectId": "bonssu-e0c96",
	"appId": "1:149405333476:web:9abe9c98be3fa2d795b9f1",
	"databaseURL": "https://bonssu-e0c96-default-rtdb.firebaseio.com",
	"storageBucket": "bonssu-e0c96.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDRkgO_jjArYQ-eKzM1r42KUKLSCzSlLmE",
	"authDomain": "bonssu-e0c96.firebaseapp.com",
	"messagingSenderId": "149405333476",
	"measurementId": "G-K07ZZ8KE9Z"
};