import {
    FETCH_OUTSTATION_ADDRESS,
    FETCH_OUTSTATION_ADDRESS_SUCCESS,
    FETCH_OUTSTATION_ADDRESS_FAILED,
    EDIT_OUTSTATION_ADDRESS
} from "../store/types";

export const INITIAL_STATE = {
    outstation:null,
    loading:false,
    error:{
        flag:false,
        msg:null
    }
}

export const outstationreducer = (state = INITIAL_STATE, action)=>{
    switch(action.type){
        case FETCH_OUTSTATION_ADDRESS:
            return{
                ...state,
                loading:true
            };

        case FETCH_OUTSTATION_ADDRESS_SUCCESS:
            return{
                ...state,
                outstation:action.payload,
                loading:false
            };

        case FETCH_OUTSTATION_ADDRESS_FAILED:
            return{
                ...state,
                outstation:null,
                loading:false,
                error:{
                    flag:true,
                    msg:action.payload
                }
            };

        case EDIT_OUTSTATION_ADDRESS:
            return state;

        default:
            return state;
    }
}