import {
    FETCH_OUTSTATION_ADDRESS,
    FETCH_OUTSTATION_ADDRESS_SUCCESS,
    EDIT_OUTSTATION_ADDRESS,
    FETCH_OUTSTATION_ADDRESS_FAILED
} from "../store/types"

import {firebase} from "../config/configureFirebase";
import { onValue, push, set, remove } from "firebase/database";

export const fetchOutStationAddress = () => (dispatch) =>{

    const{
        outstationRef
    } = firebase
    dispatch({
        type:FETCH_OUTSTATION_ADDRESS,
        payload:null
    });

    onValue(outstationRef, snapshot =>{
        if(snapshot.val()){
            const data = snapshot.val();
            const arr = Object.keys(data).map(item =>{
                data[item].id = item;
                return data[item];
            });

            dispatch({
                type:FETCH_OUTSTATION_ADDRESS_SUCCESS,
                payload:arr
            });
        }else{
            dispatch({
                type:FETCH_OUTSTATION_ADDRESS_FAILED,
                payload:"No outstation available"
            })
        }
    })

}

export const editOutStationAddress = (outstation, method)=>(dispatch)=>{

    const {
        outstationRef,
        outstationEditRef
    } = firebase;

    dispatch({
        type: EDIT_OUTSTATION_ADDRESS,
        payload:{method, outstation}
    });

    if(method === "Add"){
        push(outstationRef, outstation);
    }else if(method === "Delete"){
        remove(outstationEditRef(outstation.id));
    }else{
        set(outstationEditRef(outstation.id),outstation)
    }
}