import React, { useState, useEffect, useRef } from 'react';
import {Grid} from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from '../components/AlertDialog';
import { makeStyles } from '@mui/styles';
import { api } from 'common';
import { useTranslation } from "react-i18next";
import { colors } from '../components/Theme/WebTheme';
import MaterialTable from 'material-table';
import CircularLoading from "../components/CircularLoading";
import { downloadCsv } from '../common/sharedFunctions';
import { MAIN_COLOR ,SECONDORY_COLOR } from "../common/sharedFunctions";

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    textField: {
        "& label.Mui-focused": {
            color: MAIN_COLOR,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: MAIN_COLOR,
        },
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: MAIN_COLOR,
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: MAIN_COLOR,
            },
        },
    },
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: 600,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    container: {
        marginTop: theme.spacing(1),
        backgroundColor: colors.SECONDORY_COLOR,
        alignContent: 'center',
        borderRadius: "8px",
        width: '100%',
    },
    inputContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        backgroundColor: colors.WHITE,
        borderRadius: "8px",
        width: '50%',
    },
    container1: {
        backgroundColor: colors.WHITE,
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        padding: '30px',
        width: '100%',
        top: "19px",
        boxShadow: "4px 4px 6px #9E9E9E"
    },
    title: {
        color: colors.BLACK,
        padding: '10px'
    },
    gridcontainer: {
        alignContent: 'center'
    },
    items: {
        margin: 0,
        width: '100%'
    },
    input: {
        fontSize: 18,
        color: "#000"
    },
    inputdimmed: {
        fontSize: 18,
        color: colors.THIRDCOLOR
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    },
    carphotoRtl: {
        height: '16px',
        marginLeft: '10px'
    },
    buttonStyle: {
        margin: 0,
        width: '100%',
        height: 40,
        borderRadius: "30px",
        backgroundColor: MAIN_COLOR,
        color: '#FFF'
    },
    inputRtl: {
        "& label": {
            right: 25,
            left: "auto"
        },
        "& legend": {
            textAlign: "right",
            marginRight: 18
        }
    },
    rightRty: {
        "& legend": {
            marginRight: 30
        }
    }
}));


function Outstation() {

    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir();
    const {
        fetchOutStationAddress,
        editOutStationAddress
    } = api;
    const dispatch = useDispatch();
    const classes = useStyles();
    const settings = useSelector(state => state.settingsdata.settings);
    const outstation = useSelector(state => state.outstationdata.outstation);
    const cartypes = useSelector(state => state.cartypes);
    const rootRef = useRef(null);
    const [loader, setLoader] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
    const [outstationData, setOutstationData] = useState([]);
    const [outstationCarType, setOutstationCarType] = useState([])

    const subColumn = outstationCarType?.map((item) => {
        return (
            { title: t(`${item.name}`) + t(' price'), field: `${item.id}`, cellStyle: { textAlign: 'center', border: "1px solid rgba(224, 224, 224, 1)", } }
        )
    })

    const columns = [
        { title: t('location_pick'), field: 'pickupLoc', cellStyle: { textAlign: 'center', border: "1px solid rgba(224, 224, 224, 1)", } },
        { title: t('pickup_type'), field: 'pickupType', lookup: { airport: t('airport'), town: t('town')}, cellStyle: { textAlign: 'center', border: "1px solid rgba(224, 224, 224, 1)", } },
        { title: t('location_drop'), field: 'dropLoc', cellStyle: { textAlign: 'center', border: "1px solid rgba(224, 224, 224, 1)", } },     
        { title: t('drop_type'), field: 'dropType', lookup: { airport: t('airport'), town: t('town')}, cellStyle: { textAlign: 'center', border: "1px solid rgba(224, 224, 224, 1)", } },
        ...subColumn,
    ];

    useEffect(() => {
        if (cartypes && cartypes.cars) {
            setOutstationCarType(cartypes.cars);
        }
    }, [cartypes])

    useEffect(() => {
        if (outstation) {
            setOutstationData(outstation)
        } else {
            setOutstationData([])
        }
    }, [outstation])

    const handleCommonAlertClose = (e) => {
        e.preventDefault();
        setCommonAlert({ open: false, msg: '' })
    };

    return (
        <>
            {loader ? <CircularLoading /> :
                <div className={classes.container} ref={rootRef}>
                    <Grid item xs={12} sm={12} md={8} lg={8}>

                            <Grid container>
                                <Grid item xs={12} >
                                    <MaterialTable
                                        title={t('fixed_prices')}
                                        columns={columns}
                                        style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E" }}
                                        data={outstationData}
                                        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                        options={{
                                            pageSize: 10,
                                            pageSizeOptions: [10, 15, 20],
                                            exportCsv: (columns, data) => {
                                                let hArray = [];
                                                const headerRow = columns.map(col => {
                                                    if (typeof col.title === 'object') {
                                                        return col.title.props.text;
                                                    }
                                                    hArray.push(col.field);
                                                    return col.title;
                                                });
                                                const dataRows = data.map(({ tableData, ...row }) => {
                                                    row.createdAt = new Date(row.createdAt).toLocaleDateString() + ' ' + new Date(row.createdAt).toLocaleTimeString()
                                                    let dArr = [];
                                                    for (let i = 0; i < hArray.length; i++) {
                                                        dArr.push(row[hArray[i]]);
                                                    }
                                                    return Object.values(dArr);
                                                })
                                                const { exportDelimiter } = ",";
                                                const delimiter = exportDelimiter ? exportDelimiter : ",";
                                                const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join("\n");
                                                const csvFileName = 'download.csv';
                                                downloadCsv(csvContent, csvFileName);
                                            },
                                            exportButton: {
                                                csv: settings.AllowCriticalEditsAdmin,
                                                pdf: false,
                                            },
                                            maxColumnSort: "all_columns",
                                            rowStyle: (rowData) => ({
                                                backgroundColor:
                                                    selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
                                                border: "1px solid rgba(224, 224, 224, 1)",
                                            }),
                                            editable: {
                                                backgroundColor: colors.Header_Text,
                                                fontSize: "0.8em",
                                                fontWeight: 'bold ',
                                                fontFamily: 'Lucida Console", "Courier New", monospace'
                                            },
                                            headerStyle: {
                                                position: "sticky",
                                                top: "0px",
                                                fontSize: "0.8em",
                                                fontWeight: "bold ",
                                                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                                color: colors.BLACK,
                                                backgroundColor: SECONDORY_COLOR,
                                                textAlign: "center",
                                                border: "1px solid rgba(224, 224, 224, 1)",
                                            },
                                            actionsColumnIndex: -1,
                                        }}
                                        localization={{
                                            body: {
                                                addTooltip: (t('add')),
                                                deleteTooltip: (t('delete')),
                                                editTooltip: (t('edit')),
                                                emptyDataSourceMessage: (
                                                    (t('blank_message'))
                                                ),
                                                editRow: {
                                                    deleteText: (t('delete_message')),
                                                    cancelTooltip: (t('cancel')),
                                                    saveTooltip: (t('save'))
                                                },
                                            },
                                            toolbar: {
                                                searchPlaceholder: (t('search')),
                                                exportTitle: (t('export')),
                                            },
                                            header: {
                                                actions: (t('actions'))
                                            },
                                            pagination: {
                                                labelDisplayedRows: ('{from}-{to} ' + (t('of')) + ' {count}'),
                                                firstTooltip: (t('first_page_tooltip')),
                                                previousTooltip: (t('previous_page_tooltip')),
                                                nextTooltip: (t('next_page_tooltip')),
                                                lastTooltip: (t('last_page_tooltip'))
                                            },
                                        }}
                                        editable={{
                                            onRowAdd: newData =>
                                                new Promise((resolve, reject)=> {
                                                  setTimeout(() => {
                                                    if(!(newData && newData.pickupLoc && newData.dropLoc )){
                                                        setLoader(false);
                                                      alert(t('no_details_error'));
                                                      reject();
                                                    } else if(newData.pickupType === 'airport' && newData.dropType === 'airport' ){
                                                        setLoader(false);
                                                        alert(t('pickuptype_droptype_not_same'));
                                                        reject();
                                                    } else if(newData.pickupLoc === newData.dropLoc ){
                                                        setLoader(false);
                                                        alert(t('pickuploc_droploc_not_same'));
                                                        reject();
                                                    }else{
                                                      dispatch(editOutStationAddress(newData, "Add"));
                                                      resolve();
                                                    }
                                                  }, 600);
                                                }),
                                            onRowUpdate: (newData, oldData) =>
                                                settings.AllowCriticalEditsAdmin ?
                                                    new Promise((resolve, reject) => {
                                                        setLoader(true);
                                                        setTimeout(() => {
                                                            if (newData !== oldData) {
                                                                if(newData.pickupType === 'airport' && newData.dropType === 'airport' ){
                                                                    setLoader(false);
                                                                    alert(t('pickuptype_droptype_not_same'));
                                                                    reject();
                                                                } else if(newData.pickupLoc === newData.dropLoc ){
                                                                    setLoader(false);
                                                                    alert(t('pickuploc_droploc_not_same'));
                                                                    reject();
                                                                }else{
                                                                    resolve();
                                                                    delete newData.tableData;
                                                                    dispatch(editOutStationAddress(newData, "Update"));
                                                                    dispatch(fetchOutStationAddress());
                                                                    setLoader(false);
                                                                }
                                                            } else {
                                                                setLoader(false);
                                                                setCommonAlert({ open: true, msg: t('proper_price') });
                                                                reject();
                                                            }

                                                        }, 600);
                                                    }) :
                                                    new Promise(resolve => {
                                                        setTimeout(() => {
                                                            resolve();
                                                            setCommonAlert({ open: true, msg: t('demo_mode') });
                                                        }, 600);
                                                    }),
                                            onRowDelete: oldData =>
                                                settings.AllowCriticalEditsAdmin ?
                                                    new Promise(resolve => {
                                                        setTimeout(() => {
                                                            resolve();
                                                            dispatch(editOutStationAddress(oldData, "Delete"));
                                                        }, 600);
                                                    })
                                                    :
                                                    new Promise(resolve => {
                                                        setTimeout(() => {
                                                            resolve();
                                                            setCommonAlert({ open: true, msg: t('demo_mode') });
                                                        }, 600);
                                                    })
                                        }}
                                    />
                                </Grid>
                            </Grid>
                    </Grid>
                    <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
                </div>
            }
        </>
    )
}

export default Outstation;