import React, { useState, useEffect } from 'react';
import {
    GoogleMap,
    Marker,
    InfoWindow
} from "@react-google-maps/api";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import { api } from 'common';
import { colors } from 'components/Theme/WebTheme';
import { Typography, Grid, Card } from "@mui/material";
import { SECONDORY_COLOR } from "../common/sharedFunctions";
import mapStyles from '../config/map2.json'; 

const BirdsEyeView = () => {
    const mylocation = {lat:6.71,lng:-1.69};
    const [locations, setLocations] = useState([]);

    const [settings, setSettings] = useState({});
    const {t} = useTranslation();
    const usersdata = useSelector(state => state.usersdata);
    const bookinglistdata = useSelector(state => state.bookinglistdata);
    const settingsdata = useSelector(state => state.settingsdata);
    const auth = useSelector(state => state.auth);
    const cars = useSelector(state => state.cartypes.cars);
    const dispatch = useDispatch();

    const { fetchDrivers, clearFetchDrivers } = api;

    useEffect(() => {
        dispatch(fetchDrivers('web'));
        return () => {
            dispatch(clearFetchDrivers());
        };
    }, [dispatch, fetchDrivers, clearFetchDrivers]);

    // useEffect(() => {
    //     if (mylocation == null) {
    //         navigator.geolocation.getCurrentPosition(
    //             position => setMylocation({
    //                 lat: position.coords.latitude,
    //                 lng: position.coords.longitude
    //             }),
    //             error => console.log(error)
    //         );
    //     }
    // }, [mylocation]);

    useEffect(() => {
        if (settingsdata.settings) {
            setSettings(settingsdata.settings);
        }
    }, [settingsdata.settings]);

    useEffect(() => {
        if (usersdata.drivers && bookinglistdata.bookings) {
            const liveBookings = bookinglistdata.bookings.filter(bkg => bkg.status === 'STARTED' || bkg.status === 'ACCEPTED');
            const drivers = usersdata.drivers;
            let locs = [];
            for (let i = 0; i < drivers.length; i++) {
                if (drivers[i].location) {
                    let carImage = 'https://cdn.pixabay.com/photo/2012/04/15/22/09/car-35502__480.png';
                    let bookingRef = null;
                    for (let j = 0; j < cars.length; j++) {
                        if (cars[j].name === drivers[i].carType) {
                            carImage = cars[j].image;
                        }
                    }
                    for (let j = 0; j < liveBookings.length; j++) {
                        if (liveBookings[j].driver === drivers[i].uid) {
                            bookingRef = liveBookings[j].reference;
                        }
                    }
                    locs.push({
                        id: i,
                        lat: drivers[i].location.lat,
                        lng: drivers[i].location.lng,
                        drivername: drivers[i].firstName + ' ' + drivers[i].lastName,
                        carnumber: drivers[i].vehicleNumber,
                        cartype: drivers[i].carType,
                        carImage: carImage,
                        bookingRef: bookingRef
                    });
                }
            }
            setLocations(locs);
        }
    }, [usersdata.drivers, auth.profile.usertype, auth.profile.uid, cars, bookinglistdata.bookings, settings]);

    return (
        bookinglistdata.loading || usersdata.loading ? <CircularLoading /> :
            <div>
                <Card
                    style={{
                        borderRadius: "19px",
                        backgroundColor: colors.WHITE,
                        maxWidth: "100%",
                        minHeight:"100vh",
                        maxHeight:"100%",
                        padding: 10,
                        alignItems: "center",
                        justifyContent: "center",
                        boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                    }}
                >
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Grid container style={{textAlign:"center", justifyContent:"center", alignItems:"center" }} >
                        <Typography variant="h4" style={{ textAlign: "center", marginBottom:5 }}>{t('real_time_birds_eye_text')}</Typography>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ backgroundColor: '#fff', borderRadius: 20, overflow: 'hidden',minHeight:"70%" }} boxShadow={3}>
                                {mylocation && mylocation.lat && locations?
                                    <GoogleMap
                                        zoom={3}
                                        center={mylocation}
                                        mapContainerStyle={{ height: `80vh`}}
                                        options={{ styles: mapStyles }} 
                                    >
                                        {locations.map(marker => (
                                            <Marker
                                                position={{ lat: marker.lat, lng: marker.lng }}
                                                key={marker.id}
                                                icon={{
                                                    url: marker.carImage,
                                                    scaledSize: new window.google.maps.Size(35, 25)
                                                }}
                                            >
                                                <InfoWindow
                                                    position={{ lat: marker.lat, lng: marker.lng }}
                                                    options={{ pixelOffset: new window.google.maps.Size(0, -32), disableAutoPan: true }}
                                                >
                                                    <div><b>{marker.drivername}<br />{marker.carnumber} <br/>  { "Booking : " + (marker.bookingRef? marker.bookingRef : "Idle")} </b></div>
                                                </InfoWindow>

                                            </Marker>
                                        ))}
                                    </GoogleMap>
                                    : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </div>

    )
}

export default BirdsEyeView;